import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'
import { delAction, getAction, postAction, putAction } from '@/command/netTool'
import { relateTypeList, goodsTypeList } from '@/utils/textFile'
import { message, InputNumber } from 'ant-design-vue'
let linkAddressArr = []
let linkModuleArr = []
let linkTypeArr = []
initTypeList()
async function onFetchInit(data) {
  const [dataOne, dataTwo] = await Promise.all(
    ['/config/farmGoodsCategory/list', '/config/farmKingKongItem/list'].map((e, i) => getAction(e, {}, '/api'))
  )

  linkAddressArr = dataOne.data.map((e) => {
    return { ...e, value: e.id }
  })
  linkModuleArr = dataTwo.data.map((e) => {
    return { ...e, value: e.code }
  })

  return new Promise((resolve) => {
    getAction('/farmPalmHomePageStyleConfig/getDetail').then((e) => {
      resolve({
        form: { list: data, ...e.data },
      })
    })
  })
}

function initTypeList() {
  return new Promise((resolve) => {
    getAction('/farmKingKongType/list').then((res) => {
      linkTypeArr = res.data.map((e) => {
        return { ...e, text: e.name, value: e.id }
      })
      resolve(linkTypeArr)
    })
  })
}

function getForm(form) {
  return [
    {
      title: '基础',
      form: [
        {
          name: '标题',
          type: 'input',
          labelAlign: 'left',
          key: 'name',
        },
        {
          name: '优先级',
          type: 'input',
          labelAlign: 'left',
          key: 'sort',
        },
      ],
    },
    {
      title: '分类',
      form: [
        {
          name: '分类名称',
          type: 'select',
          key: 'kingKongTypeId',
          typeData: linkTypeArr,
        },
      ],
    },
    {
      title: '链接',
      form: [
        {
          name: '链接方式',
          type: 'select',
          key: 'linkType',
          typeData: relateTypeList,
          onChange: (e) => {
            form.linkName = ''
            form.linkId = ''
          },
        },
        ...[
          // 外链
          {
            name: '链接地址',
            type: 'input',
            key: 'linkUrl',
            maxLength: 1000,
            display: form.linkType == '1',
          },
          // 内部链接
          {
            name: '链接模块',
            type: 'select',
            display: form.linkType == '2',
            key: 'linkId',
            placeholder: '请选择链接模块',
            defaultValue: 'eat_drink',
            typeData: linkModuleArr,
          },
          {
            name: '链接地址',
            type: 'select',
            key: 'categoryId',
            maxLengt: 100,
            display: form.linkType == '2' && form.linkId == 'product_type',
            typeData: linkAddressArr,
          },
          // 商品
          {
            name: '链接地址',
            type: 'select',
            key: 'goodsType',
            display: form.linkType == '3',
            defaultValue: '0',
            typeData: goodsTypeList(),
          },
          {
            name: '链接地址',
            type: 'storeSearch',
            key: 'linkName',
            valueKey: 'linkId',
            display: form.linkType == '3',
            url: `/api/config/farmKingKong/listGoodsByType?type=${form.goodsType}`,
          },
          // 店铺
          {
            name: '链接地址',
            type: 'storeSearch',
            key: 'linkName',
            valueKey: 'linkId',
            display: form.linkType == '4',
            url: `/api/commodity/farmMainBody/list?mainBodyName=${form.linkName}`,
          },
          // 活动
          {
            name: '链接地址',
            type: 'storeSearch',
            key: 'linkName',
            valueKey: 'linkId',
            display: form.linkType == '5',
            url: '/api/farmActivity/list?appCode=sznc',
          },
          // 直播
          {
            name: '链接地址',
            type: 'storeSearch',
            key: 'linkName',
            valueKey: 'linkId',
            display: form.linkType == '6',
            url: '/api/market/farmLiveRoom/list',
          },
          // 专题页
          {
            name: '链接地址',
            type: 'storeSearch',
            key: 'linkName',
            valueKey: 'linkId',
            display: form.linkType == '7',
            url: '/api/farmShowSpecial/getList',
          },
          // 抽奖
          {
            name: '链接地址',
            type: 'storeSearch',
            key: 'linkName',
            valueKey: 'linkId',
            display: form.linkType == '8',
            url: '/api/farmActivityDrawPrize/list',
          },
          // 跳转小程序
          {
            name: 'appid',
            type: 'input',
            key: 'linkUrl',
            display: form.linkType == '9',
          },
        ].filter((e) => e.display == true),
      ],
    },
    {
      form: [
        {
          name: '金刚区ICON',
          type: 'iconUpload',
          labelCol: 24,
          typeData: [
            {
              key: 'image2',
              desc: '@2x   xhdpi <br/>上传尺寸100*100px',
            },
          ],
        },
      ],
    },
  ]
}

function onOpen({ records, update }) {
  apiTool.showDrawer({
    title: '编辑',
    view: DrawerForm,
    width: '600px',
    viewProps: {
      formProps: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
      },
      form: { ...records },
      data: getForm,
    },
    success({ data, setHidden }) {
      update(data).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close,
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit,
        },
      ]
    },
  })
}

function getClassifiedForm(data) {
  return [
    {
      form: [
        {
          type: 'table',
          wrapperCol: 24,
          showFootButton: true,
          showRowSelect: false,
          showPagination: false,
          onAddData: () => {
            return {
              name: '',
              sort: '',
              upDown: '1',
              isEdit: true,
            }
          },
          columns: [
            {
              title: '分类名称',
              dataIndex: 'name',
              width: '30%',
              customRender: function (text, records, index, h) {
                if (records.isEdit) {
                  const onInput = (data) => {
                    records.name = data.target.value
                  }
                  return <a-input placeholder="分类名称" onChange={onInput} value={text} />
                }
                return records.name
              },
            },
            {
              title: '排序',
              width: '20%',
              dataIndex: 'sort',
              customRender: function (text, records, index, h) {
                if (records.isEdit) {
                  const onInput = (data) => {
                    records.sort = data
                  }
                  return <InputNumber min={0} placeholder="排序" onChange={onInput} value={text} />
                }
                return '' + records.sort
              },
            },
            {
              title: '状态',
              type: 'badge',
              dataIndex: 'upDown',
              render(data) {
                return {
                  showDot: true,
                  name: data == 0 ? '上架' : '下架',
                  color: data == 0 ? 'green' : 'red',
                }
              },
            },
            {
              title: '操作',
              dataIndex: 'productAttr',
              customRender: function (text, records, index, h) {
                const config = [
                  {
                    name: '上架',
                    type: 'primary',
                    show: records.upDown === '1',
                    onClick: () => {
                      if (records.id) {
                        getAction('/farmKingKongType/up?id=' + records.id)
                          .then((e) => {
                            records.upDown = '0'
                          })
                          .catch((err) => {
                            message.error(err.msg || '操作失败，稍后再试!')
                          })
                      } else {
                        records.upDown = '0'
                      }
                    },
                  },
                  {
                    name: '下架',
                    type: 'primary',
                    show: records.upDown === '0',
                    onClick: () => {
                      if (records.id) {
                        getAction('/farmKingKongType/down?id=' + records.id)
                          .then((e) => {
                            records.upDown = '1'
                          })
                          .catch((err) => {
                            message.error(err.msg || '操作失败，稍后再试!')
                          })
                      } else {
                        records.upDown = '1'
                      }
                    },
                  },
                  {
                    name: records.isEdit ? '保存' : '编辑',
                    type: 'primary',
                    show: true,
                    onClick: () => {
                      if (records.isEdit) {
                        if (!records.name || (!records.sort && records.sort !== 0)) {
                          message.error('请完善后再保存!')
                          return
                        }
                      }
                      records.isEdit = !records.isEdit
                    },
                  },
                  {
                    name: '删除',
                    type: 'danger',
                    show: true,
                    onClick: () => {
                      if (records.id) {
                        delAction('/farmKingKongType/del?id=' + records.id).then((e) => {
                          message.success('删除成功!')
                          data.list.splice(index, 1)
                        })
                      } else {
                        data.list.splice(index, 1)
                      }
                    },
                  },
                ]
                return (
                  <div>
                    {config.map((item) => {
                      if (!item.show) return
                      return (
                        <a-button type={item.type} ghost style={'margin-left: 8px'} onClick={item.onClick}>
                          {item.name}
                        </a-button>
                      )
                    })}
                  </div>
                )
              },
            },
          ],
          dataSource: data.list,
        },
      ],
    },
  ]
}

function classifiedForm(data) {
  apiTool.showDrawer({
    title: '分类管理',
    view: DrawerForm,
    width: '800px',
    viewProps: {
      formProps: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
      },
      form: data,
      data: getClassifiedForm,
    },
    success({ data, setHidden }) {
      if (data.list.find((e) => e.isEdit)) {
        message.error('请先保存列表数据!')
        return
      }
      postAction('/farmKingKongType/saveBatch', data.list)
        .then((res) => {
          message.success('保存成功!')
          initTypeList()
          setHidden()
        })
        .catch((err) => {
          message.error(err.msg || '保存失败，稍后再试!')
        })
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close,
        },
        {
          name: '保存',
          type: 'primary',
          onClick: submit,
        },
      ]
    },
  })
}

function createForm({ create }) {
  apiTool.showDrawer({
    title: '新增',
    view: DrawerForm,
    width: '600px',
    viewProps: {
      formProps: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
      },
      form: {
        name: '',
        sort: '',
        linkType: '',
        linkUrl: '',
        linkId: '',
        categoryId: '',
        goodsType: '',
        image2: '',
        image3: '',
      },
      data: getForm,
    },
    success({ data, setHidden }) {
      create(data).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close,
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit,
        },
      ]
    },
  })
}

function delData({ del, form = {} } = {}) {
  del(form)
}

function upDown({ update, form = {} } = {}) {
  update({
    ...form,
    status: form.status === '0' ? '1' : '0',
  })
}

const getForm1 = (formData, update, create, del) => {
  return [
    {
      type: 'radio',
      name: '金刚区选项',
      key: 'kingKongShowType',
      cols: 24,
      labelCol: 4,
      wrapperCol: 20,
      typeData: [
        {
          name: '多行',
          value: '0',
        },
        {
          name: '单行滑动',
          value: '1',
        },
        {
          name: '展开更多',
          value: '2',
        },
      ],
    },
    {
      type: 'radio',
      name: '金刚区选项',
      key: 'kingKongStyleType',
      cols: 24,
      labelCol: 4,
      wrapperCol: 20,
      typeData: [
        {
          name: '纯色',
          value: '0',
        },
        {
          name: '图片',
          value: '1',
        },
      ],
    },
    {
      type: 'colorPicker',
      name: '金刚区颜色选择',
      key: 'kingKongColor',
      cols: 24,
      labelCol: 4,
      wrapperCol: 20,
      display: formData.kingKongStyleType == '0',
    },
    {
      type: 'upload',
      name: '金刚区背景图片',
      key: 'kingKongImg',
      cols: 24,
      labelCol: 4,
      wrapperCol: 20,
      display: formData.kingKongStyleType == '1',
    },
    {
      type: 'button',
      label: '分类管理',
      cols: 4,
      props: {
        type: 'primary',
      },
      onClick: async () => {
        await initTypeList().then((list) => {
          classifiedForm({
            list: list.map((e) => ({
              ...e,
              isEdit: false,
            })),
          })
        })
      },
    },
    {
      type: 'table',
      showFootButton: true,
      cols: 24,
      dataSource: formData.list,
      wrapperCol: 24,
      on: {
        handleAddData: () => createForm({ create }),
      },
      onAddData: () => {
        return {
          image2: '',
          name: '',
          sort: '',
          editDelete: '',
          isEdit: false,
        }
      },
      columns: [
        {
          title: '标题名称',
          dataIndex: 'name',
          type: 'lt-200',
          customRender: function customRender(text, records, index, h) {
            return (
              <div>
                <img
                  src={records.image2}
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '10px',
                  }}
                />
                <span>{text}</span>
              </div>
            )
          },
        },
        {
          title: '分类',
          dataIndex: 'kingKongTypeId',
          type: 'lt-200',
          filters: linkTypeArr,
          filterMultiple: false,
          customRender: function customRender(text, records, index, h) {
            let value = ''
            if (text) {
              const textObj = linkTypeArr.find((e) => e.id === text)
              value = textObj ? textObj.name : ''
            }
            return <div>{value}</div>
          },
          onFilter: (a, b) => {
            return b.kingKongTypeId === a
          },
        },
        {
          title: '排序',
          dataIndex: 'sort',
          colSpan: { span: 8 },
        },
        {
          title: '状态',
          type: 'badge',
          dataIndex: 'status',
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          title: '操作',
          width: '110px',
          tableType: 'editDelete',
          onUpDown: (text, records) => upDown({ update, form: records }),
          onEdit: (text, records) => onOpen({ update, records }),
          onDel: (text, records) => delData({ del, form: records }),
          //   customRender: function customRender(text, records, index, h) {
          //     return (
          //       <Button onClick={() => onOpen({ update, records })} ghost={true} type="primary">
          //         编辑
          //       </Button>
          //     )
          //   }
        },
      ],
    },
  ].filter((e) => (e.display == undefined ? true : e.display))
}

function onSubmit({ detail, form }) {
  putAction('/farmPalmHomePageStyleConfig/updateKingKang', form).then((e) => {
    message.success('保存成功')
    throw ''
  })
}

export default function () {
  const { update, create, del } = arguments[0]
  return {
    showFoot: false,
    url: '/config/farmKingKong/list',
    edit: '/config/farmKingKong/update',
    del: '/config/farmKingKong/delete?id=',
    create: '/config/farmKingKong/save',
    type: 'cardForm',
    custom: true,
    onFetchInit,
    onSubmit: onSubmit,
    data: () => {
      return [
        {
          form: (formData) => getForm1(formData, update, create, del),
        },
      ]
    },
  }
}
